import { Parallax } from "react-scroll-parallax";
import "./Car.css";
import CarImg from "../../images/car-down.png";

const Car = () => {
    return (
        <div className="vehicle">
            <div className="track" />
            <Parallax translateY={[-120, 300]} className="vehicle-img">
                <img src={CarImg} alt="" />
            </Parallax>
            <div className="circle-item-wrapper">
                <div className="circle-item">
                    <div className="round" />
                    <div>
                        <span>450 м</span>
                        <p>до Центральной мечети</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="round" />
                    <div>
                        <span>900 м</span>
                        <p>до Зеленого базара</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="round" />
                    <div>
                        <span>1 км</span>
                        <p>до вокзала Алматы-2</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="round" />
                    <div>
                        <span>1,2 км</span>
                        <p>до арбата</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="round" />
                    <div>
                        <span>1,3 км</span>
                        <p>до Парка 28 панфиловцев</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="round" />
                    <div>
                        <span>1,6 км</span>
                        <p>до Парка культуры и отдыха</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Car;
