import React from "react";
import { motion } from "framer-motion";
import "./Advantages.css";
import BaseForm from "../form/BaseForm";
import Bag from "../../images/adv1.png";
import Girl from "../../images/adv2.png";
import Clock from "../../images/adv3.png";
import Tree from "../../images/adv4.png";
import Couple from "../../images/adv5.png";
import Marker from "../../images/adv6.png";

const Advantages = () => {
    return (
        <section id="advantages">
            <div className="inner">
                <motion.div
                    className="left"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ ease: "easeOut", duration: 1 }}
                >
                    <div className="column">
                        <div className="item">
                            <h2>
                                Школа и <br /> детский сад
                            </h2>
                            <p>
                                Пусть дети будут рядом – школа и детский сад во
                                дворе RAMS Saiahat
                            </p>
                            <img src={Bag} alt="bag" />
                        </div>
                        <div className="item">
                            <h2>
                                Просторный <br />
                                внутренний
                                <br /> двор
                            </h2>
                            <img src={Girl} alt="girl" />
                        </div>
                    </div>
                    <div className="item">
                        <h2>
                            Башня <br /> с часами
                        </h2>
                        <p>
                            Возрождение легендарной достопримечательности и
                            новое место для теплых встреч.
                        </p>
                        <img src={Clock} alt="girl" />
                    </div>
                </motion.div>
                <motion.div
                    className="right"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ ease: "easeOut", duration: 1 }}
                >
                    <div className="row">
                        <div className="item item4">
                            <h2>
                                Открытый <br /> парк для всех
                            </h2>
                            <p>
                                Сотни деревьев, кустарников и цветов на месте
                                легендарного автовокзала Саяхат.
                            </p>
                            <img src={Tree} alt="" />
                        </div>
                        <div className="item item5">
                            <h2>
                                Все важное
                                <br /> рядом
                            </h2>
                            <div className="overlay"></div>
                            <img src={Couple} alt="couple" />
                        </div>
                    </div>
                    <div className="item item6">
                        <h2>
                            На пересечении <br /> Суюнбая-Райымбек
                        </h2>
                        <p>
                            Вблизи от станции метро Райымбек Батыра, вокзала
                            Алматы-2 и дороги в аэропорт.
                        </p>
                        <img src={Marker} alt="marker" />
                    </div>
                </motion.div>
                {/* <table>
                    <tr>
                        <td className="cell1">
                            <div className="content item item1">
                                <h2>Школа и детский сад</h2>
                                <p>
                                    Пусть дети будут рядом – школа и детский сад
                                    во дворе RAMS Saiahat
                                </p>
                            </div>
                        </td>
                        <td className="cell2" rowSpan="2">
                            <div className="content item item3">
                                <h2>Башня с часами</h2>
                                <p>
                                    Возрождение легендарной
                                    достопримечательности и новое место для
                                    теплых встреч.
                                </p>
                            </div>
                        </td>
                        <td className="cell3">
                            <div className="content item item2">
                                <h2>Открытый парк для всех</h2>
                                <p>
                                    Сотни деревьев, кустарников и цветов на
                                    месте легендарного автовокзала Саяхат.
                                </p>
                            </div>
                        </td>
                        <td className="cell4">
                            <div className="content item item5">
                                <h2>Все важное рядом</h2>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="cell1">
                            <div className="content item item4">
                                <h2>Просторный внутренний двор</h2>
                            </div>
                        </td>
                        <td className="cell5" colSpan="2">
                            <div className="content item item6">
                                <h2>На пересечении Суюнбая-Райымбек</h2>
                                <p>
                                    Вблизи от станции метро Райымбек Батыра,
                                    вокзала Алматы-2 и дороги в аэропорт.
                                </p>
                            </div>
                        </td>
                    </tr>
                </table> */}
                {/* <table className="short">
                    <tr>
                        <td className="cell1">
                            <div className="content item item1">
                                <h2>Школа и детский сад</h2>
                                <p>
                                    Пусть дети будут рядом – школа и детский сад
                                    во дворе RAMS Saiahat
                                </p>
                            </div>
                        </td>
                        <td className="cell2" rowSpan="2">
                            <div className="content item item3">
                                <h2>Башня с часами</h2>
                                <p>
                                    Возрождение легендарной
                                    достопримечательности и новое место для
                                    теплых встреч.
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="cell1">
                            <div className="content item item4">
                                <h2>Просторный внутренний двор</h2>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="cell3">
                            <div className="content item item2">
                                <h2>Открытый парк для всех</h2>
                                <p>
                                    Сотни деревьев, кустарников и цветов на
                                    месте легендарного автовокзала Саяхат.
                                </p>
                            </div>
                        </td>
                        <td className="cell4">
                            <div className="content item item5">
                                <h2>Все важное рядом</h2>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="cell5" colSpan="2">
                            <div className="content item item6">
                                <h2>На пересечении Суюнбая-Райымбек</h2>
                                <p>
                                    Вблизи от станции метро Райымбек Батыра,
                                    вокзала Алматы-2 и дороги в аэропорт.
                                </p>
                            </div>
                        </td>
                    </tr>
                </table> */}
            </div>
            <BaseForm type="up" />
        </section>
    );
};

export default Advantages;
