import * as React from "react";
const SVGComponent = (props) => (
    <svg
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.8885 17.7099C7.35554 16.2107 4.51387 13.4453 2.91687 9.96703L2.91622 9.96561L2.48448 9.01594C1.89387 7.71681 2.33002 6.18111 3.5153 5.38635L4.626 4.6416C4.98464 4.40113 5.46756 4.47443 5.73868 4.8105L7.76358 7.32045C8.06098 7.68911 7.99171 8.2312 7.61116 8.51322L6.56345 9.28965C7.54624 11.282 9.16378 12.8995 11.1561 13.8823L11.9326 12.8346C12.2146 12.4541 12.7567 12.3848 13.1253 12.6822L15.6352 14.707C15.9713 14.9782 16.0446 15.4612 15.804 15.8199L15.0639 16.923C14.2736 18.1009 12.7505 18.5395 11.4548 17.9622L10.8885 17.7099Z"
            fill="#F0641E"
        />
        <path
            d="M10.207 9.24999C10.207 9.41575 10.2729 9.57472 10.3901 9.69193C10.5073 9.80914 10.6663 9.87499 10.832 9.87499H14.0224C14.3676 9.87499 14.6474 9.59516 14.6474 9.24999C14.6474 8.90481 14.3676 8.62499 14.0224 8.62499H12.3409L14.9734 5.99248C15.2175 5.7484 15.2175 5.35267 14.9734 5.1086C14.7293 4.86452 14.3336 4.86452 14.0895 5.1086L11.457 7.7411V6.05963C11.457 5.71445 11.1772 5.43463 10.832 5.43463C10.4869 5.43463 10.207 5.71445 10.207 6.05963V9.24999Z"
            fill="#828282"
        />
    </svg>
);
export default SVGComponent;
