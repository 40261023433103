import React, { useState, useRef, useEffect } from "react";

import Percentage from "../percentage/Percentage";
import BaseForm from "../form/BaseForm";
import Poster from "../../images/poster.png";
import Play from "../../svg/Play.svg";
import { motion } from "framer-motion";
import "./Progress.css";

const Modal = ({ showModal, closeModal }) => {
    const modalRef = useRef();

    const handleCloseModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (showModal) {
            document.addEventListener("mousedown", handleCloseModal);
        } else {
            document.removeEventListener("mousedown", handleCloseModal);
        }

        return () => {
            document.removeEventListener("mousedown", handleCloseModal);
        };
    }, [showModal, closeModal]);

    if (!showModal) {
        return null;
    }

    return (
        <div className="modal">
            <div ref={modalRef} className="modal-content">
                <span className="close" onClick={closeModal}>
                    &times;
                </span>
                <iframe
                    src="https://www.youtube.com/embed/kzfd_1kBxkY?si=zCIX2LIN_Vo8asSI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    );
};

const Progress = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <section id="progress">
            <div className="inner">
                <div className="progress-wrapper">
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "easeOut",
                            duration: 1,
                            delay: 0.5,
                        }}
                        className="progress-left"
                    >
                        <h4>RAMS Saiahat</h4>
                        <h2>Ход строительства</h2>
                        <p>
                            Рады поделиться с вами ходом строительства жилого{" "}
                            <br />
                            комплекса RAMS Saiahat. <br />
                            Подробнее смотрите в нашем видео
                        </p>
                        <button onClick={openModal}>Смотреть</button>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "easeOut",
                            duration: 1,
                            delay: 1,
                        }}
                        className="progress-percentage"
                    >
                        <h4>
                            Строительные работы <br /> завершены на
                        </h4>
                        <Percentage />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "easeOut",
                            duration: 1,
                            delay: 1.5,
                        }}
                        className="progress-video"
                    >
                        <img src={Poster} alt="poster" />
                        <img
                            src={Play}
                            alt="play"
                            className="playvideo"
                            onClick={openModal}
                        />
                    </motion.div>
                </div>
                <Modal showModal={showModal} closeModal={closeModal} />
                <BaseForm />
            </div>
        </section>
    );
};

export default Progress;
