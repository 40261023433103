import "./App.css";
import React, { useRef, useEffect, useContext } from "react";
import Advantages from "./components/advantages/Advantages";
import AllInOne from "./components/all-in-one/AllInOne";
import Progress from "./components/progress/Progress";
import Banner from "./components/banner/Banner";
import Footer from "./components/footer/Footer";
import Location from "./components/location/Location";
import ModalHeader from "./components/modalHeader/ModalHeader";
import ModalForm from "./components/modalForm/ModalForm";
import Widget from "./components/widget/Widget";
import { AppContext } from "./components/context/Context";
import Success from "./components/form/Success";

function App() {
    const { isFormOpen, setIsFormOpen, is404, setIs404, isSubmitted } =
        useContext(AppContext);
    return (
        <div className="App">
            <ModalHeader />
            <Banner />
            <Advantages />
            <Location />
            <AllInOne />
            <Progress />
            {/* <Widget /> */}
            <Footer />
            <ModalForm
                isOpen={isFormOpen}
                setIsOpen={setIsFormOpen}
                formId="pop-up"
            />
            <Success />
        </div>
    );
}

export default App;
