import React from "react";
import { motion } from "framer-motion";
import "./BaseForm.css";
import Form from "./Form";
import FormSvg from "../../svg/FormSvg";
import Img from "../../images/form.jpg";

const BaseForm = (props) => {
    return (
        <motion.div
            id="form"
            style={{ backgroundColor: props.type !== "up" ? "#fff" : "" }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1 }}
        >
            {props.type === "up" ? (
                <FormSvg className="svg" />
            ) : (
                <img src={Img} alt="" className="img" />
            )}

            <div className="text">
                <p className="orange">Заявка</p>
                <h2 className="primary">Получите специальную цену </h2>
                <p>Оставьте заявку, чтобы получить выгодные условия</p>
            </div>
            <Form formId={"body"} />
        </motion.div>
    );
};

export default BaseForm;
