import * as React from "react";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const SVGComponent = (props) => (
    <svg viewBox="0 0 138 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ cursor: "pointer" }} onClick={scrollToTop}>
        <path
            d="M52.3748 9.79587C52.5432 9.70389 52.6753 9.57637 52.7726 9.41442C52.8699 9.25247 52.9189 9.0674 52.9189 8.86171C52.9189 8.66796 52.8699 8.49065 52.7726 8.33026C52.6753 8.16939 52.5415 8.04297 52.3705 7.94881C52.1994 7.85526 52.0103 7.80826 51.8029 7.80826H50.2317V9.93377H51.8072C52.0179 9.93377 52.2077 9.8877 52.3748 9.79586L52.3748 9.79587ZM51.8507 6.75049C52.2847 6.75049 52.6736 6.8386 53.0174 7.01466C53.3607 7.1915 53.6285 7.43939 53.8218 7.75786C54.0148 8.07742 54.1116 8.44583 54.1116 8.86172C54.1116 9.31314 53.9917 9.7056 53.7524 10.0388C53.513 10.372 53.1907 10.6095 52.7848 10.748L54.2601 13.2314H52.8897L51.602 10.9292C51.5437 10.9329 51.4595 10.9346 51.3478 10.9346H50.2316V13.2314H49.0391V6.7505H51.8507V6.75049Z"
            fill="#F0641E"
        />
        <path
            d="M59.7353 10.9869H61.9196L60.8656 8.43251L60.8371 8.27599H60.8234L60.7842 8.43251L59.7353 10.9869ZM62.8438 13.2313L62.3267 11.978H59.3281L58.811 13.2313H57.5508L60.2622 6.74585H61.3927L64.1041 13.2313H62.8438Z"
            fill="#F0641E"
        />
        <path
            d="M67.9707 6.75049H69.0099L71.2559 9.77169L73.5175 6.75049H74.5577V13.2314H73.3645V8.65215L71.415 11.3014H71.0938L69.1639 8.64222V13.2314H67.9707V6.75049Z"
            fill="#F0641E"
        />
        <path
            d="M79.8552 13.0791C79.4581 12.8788 79.1649 12.6199 78.9762 12.3025C78.7875 11.9841 78.6934 11.6464 78.6934 11.2874H79.8861C79.8861 11.4905 79.9438 11.6749 80.0587 11.8401C80.1732 12.0049 80.3416 12.1363 80.5621 12.2331C80.7827 12.3305 81.0406 12.3781 81.3377 12.3781C82.3054 12.3781 82.7894 12.0558 82.7894 11.4111C82.7894 11.2173 82.7296 11.0575 82.6097 10.9328C82.4897 10.8064 82.3231 10.7051 82.109 10.6269C81.8956 10.5496 81.6221 10.4791 81.2898 10.4145C80.8333 10.3199 80.4323 10.205 80.0874 10.072C79.743 9.93846 79.452 9.73975 79.216 9.47604C78.9789 9.21279 78.8611 8.87292 78.8611 8.45656C78.8611 8.09419 78.9486 7.77463 79.1247 7.49604C79.3001 7.21868 79.5703 6.99918 79.9344 6.83893C80.2985 6.67854 80.7536 6.59802 81.2998 6.59802C81.8295 6.59802 82.2778 6.67854 82.6458 6.83893C83.0127 6.99918 83.2889 7.22518 83.4749 7.51744C83.6597 7.81016 83.7522 8.15113 83.7522 8.54188H82.559C82.559 8.27537 82.4528 8.0582 82.2382 7.88896C82.0243 7.72096 81.7068 7.63673 81.285 7.63673C80.835 7.63673 80.517 7.71444 80.3316 7.87034C80.1468 8.02639 80.0544 8.22278 80.0544 8.4609C80.0544 8.6393 80.1127 8.78976 80.2309 8.91184C80.3492 9.03392 80.5264 9.13568 80.7634 9.21666C80.9988 9.29763 81.307 9.36821 81.6876 9.4284C82.3868 9.55592 82.9463 9.76983 83.3666 10.072C83.7857 10.374 83.9964 10.8222 83.9964 11.416C83.9964 11.8291 83.8875 12.1832 83.6708 12.4781C83.454 12.7743 83.1448 12.9981 82.7466 13.1507C82.3472 13.3029 81.8791 13.3796 81.3427 13.3796C80.7486 13.3796 80.2524 13.2793 79.8552 13.0791V13.0791Z"
            fill="#F0641E"
        />
        <path
            d="M50.1424 32.2465C49.2656 31.8048 48.6186 31.2335 48.2032 30.5325C47.7879 29.8322 47.5801 29.0857 47.5801 28.2932H50.2112C50.2112 28.7426 50.3376 29.1491 50.5918 29.5124C50.8454 29.8774 51.2157 30.1664 51.7019 30.3799C52.1876 30.5944 52.7581 30.7005 53.4133 30.7005C55.5482 30.7005 56.615 29.9898 56.615 28.5668C56.615 28.139 56.4835 27.787 56.2194 27.5101C55.9547 27.2332 55.5863 27.0094 55.1142 26.8376C54.6422 26.6651 54.0399 26.5103 53.3071 26.3692C52.2999 26.1585 51.4159 25.9067 50.6551 25.6123C49.8943 25.3179 49.2528 24.8806 48.732 24.2989C48.2099 23.7172 47.9497 22.967 47.9497 22.0491C47.9497 21.2496 48.1438 20.5438 48.5307 19.9308C48.9185 19.3174 49.5131 18.8336 50.3174 18.4796C51.12 18.126 52.1239 17.9487 53.3286 17.9487C54.4982 17.9487 55.4877 18.126 56.2986 18.4796C57.1084 18.8336 57.7179 19.3327 58.1261 19.9773C58.5348 20.6226 58.7394 21.376 58.7394 22.2381H56.1084C56.1084 21.6492 55.8723 21.1693 55.3998 20.7976C54.9278 20.4267 54.2275 20.2405 53.2973 20.2405C52.3038 20.2405 51.6025 20.4124 51.1936 20.7555C50.7855 21.0991 50.5814 21.5336 50.5814 22.0595C50.5814 22.452 50.7113 22.783 50.972 23.0523C51.2327 23.3227 51.6238 23.547 52.1448 23.7254C52.6662 23.9044 53.3467 24.0603 54.1852 24.1932C55.7282 24.474 56.9621 24.9468 57.8885 25.6123C58.8154 26.2778 59.2785 27.2672 59.2785 28.5772C59.2785 29.489 59.0387 30.2693 58.5595 30.9216C58.0805 31.5739 57.401 32.067 56.5203 32.4041C55.639 32.7396 54.6076 32.9092 53.4233 32.9092C52.1134 32.9092 51.0199 32.6881 50.1424 32.2465L50.1424 32.2465Z"
            fill="#00705A"
        />
        <path
            d="M64.8755 27.6307H69.6944L67.3697 21.9958L67.3059 21.6494H67.2739L67.1899 21.9958L64.8755 27.6307ZM71.7337 32.5822L70.5927 29.817H63.9772L62.8352 32.5822H60.0566L66.0374 18.2745H68.5315L74.5134 32.5822H71.7337Z"
            fill="#00705A"
        />
        <path d="M76.5449 18.285H79.1762V32.5822H76.5449V18.285Z" fill="#00705A" />
        <path
            d="M86.0065 27.6307H90.8254L88.5007 21.9958L88.4367 21.6494H88.4049L88.3207 21.9958L86.0065 27.6307H86.0065ZM92.8647 32.5822L91.7237 29.817H85.1082L83.9661 32.5822H81.1875L87.1682 18.2745H89.6624L95.6442 32.5822H92.8647H92.8647Z"
            fill="#00705A"
        />
        <path d="M97.6816 18.285H100.313V24.2139H107.563V18.285H110.204V32.5822H107.563V26.5489H100.313V32.5822H97.6816V18.285Z" fill="#00705A" />
        <path
            d="M117.044 27.6307H121.862L119.538 21.9958L119.474 21.6494H119.442L119.358 21.9958L117.044 27.6307H117.044ZM123.902 32.5822L122.761 29.817H116.145L115.003 32.5822H112.225L118.205 18.2745H120.7L126.681 32.5822H123.902Z"
            fill="#00705A"
        />
        <path d="M130.101 20.6186H125.82V18.285H137.001V20.6186H132.721V32.5819H130.101V20.6186Z" fill="#00705A" />
        <path
            d="M5.70117 29.9294C5.70117 31.6449 7.0996 33.0361 8.82423 33.0361C10.5487 33.0361 11.9465 31.6449 11.9465 29.9294C11.9465 28.2137 10.5487 26.8228 8.82423 26.8228C7.09959 26.8228 5.70117 28.2138 5.70117 29.9294Z"
            fill="#A0D773"
        />
        <path
            d="M3.94922 8.47246C3.94922 9.63093 4.89309 10.5701 6.05777 10.5701C7.22167 10.5701 8.16633 9.63093 8.16633 8.47246C8.16633 7.31384 7.22167 6.37488 6.05777 6.37488C4.89309 6.37488 3.94922 7.31384 3.94922 8.47246Z"
            fill="#F0641E"
        />
        <path
            d="M31.0762 30.4802C31.0762 31.6386 32.0202 32.5777 33.1847 32.5777C34.3488 32.5777 35.2933 31.6386 35.2933 30.4802C35.2933 29.3215 34.3488 28.3826 33.1847 28.3826C32.0202 28.3826 31.0762 29.3215 31.0762 30.4802Z"
            fill="#F0641E"
        />
        <path
            d="M12.7578 24.0202C12.7578 25.1787 13.7018 26.1178 14.8664 26.1178C16.0304 26.1178 16.9749 25.1787 16.9749 24.0202C16.9749 22.8617 16.0304 21.9226 14.8664 21.9226C13.7018 21.9226 12.7578 22.8617 12.7578 24.0202Z"
            fill="#F0641E"
        />
        <path
            d="M22.1934 24.785C22.1934 26.8059 23.8403 28.4443 25.8719 28.4443C27.9034 28.4443 29.5499 26.8059 29.5499 24.785C29.5499 22.764 27.9034 21.1256 25.8719 21.1256C23.8403 21.1256 22.1934 22.764 22.1934 24.785Z"
            fill="#A0D773"
        />
        <path
            d="M1.6866 17.6542H4.21477C5.14617 17.6542 5.90152 18.4286 5.90152 19.384C5.90152 20.3389 5.14617 21.1133 4.21477 21.1133H1.6866C0.755199 21.1133 0 20.3389 0 19.384C0 18.4286 0.755199 17.6542 1.6866 17.6542Z"
            fill="#00705A"
        />
        <path
            d="M34.7901 7.132C34.1431 6.33792 32.9714 6.216 32.1732 6.85944L21.2047 15.715C20.4065 16.3591 20.2838 17.5242 20.9308 18.3181C21.5777 19.1128 22.7494 19.2343 23.5477 18.5907L34.5161 9.7353C35.3143 9.09169 35.4371 7.92609 34.7901 7.13202V7.132Z"
            fill="#00705A"
        />
        <path
            d="M17.9893 15.7608L12.1213 11.0519C11.3385 10.4236 10.1914 10.5456 9.56005 11.3243C8.92851 12.103 9.05107 13.2441 9.83449 13.8724L15.7018 18.5813C16.4853 19.2095 17.6317 19.0875 18.2632 18.3087C18.8946 17.53 18.7721 16.3891 17.9893 15.7608Z"
            fill="#00705A"
        />
        <path
            d="M21.2975 1.67797V4.19314C21.2975 5.11954 20.5192 5.87096 19.5587 5.87096C18.5987 5.87096 17.8203 5.11955 17.8203 4.19314V1.67797C17.8203 0.762269 18.5807 0.0173687 19.525 0H19.5924C20.5373 0.0173687 21.2975 0.762282 21.2975 1.67797H21.2975Z"
            fill="#00705A"
        />
        <path
            d="M35.0167 17.6542H37.545C38.4762 17.6542 39.2316 18.4286 39.2316 19.384C39.2316 20.3389 38.4762 21.1133 37.545 21.1133H35.0167C34.0854 21.1133 33.3301 20.3389 33.3301 19.384C33.3301 18.4286 34.0854 17.6542 35.0167 17.6542Z"
            fill="#00705A"
        />
        <path
            d="M21.2956 34.807V37.3221C21.2956 38.2485 20.5173 39 19.5567 39C18.5968 39 17.8184 38.2486 17.8184 37.3221V34.807C17.8184 33.8806 18.5968 33.1292 19.5567 33.1292C20.5173 33.1292 21.2956 33.8806 21.2956 34.807Z"
            fill="#00705A"
        />
    </svg>
);
export default SVGComponent;
