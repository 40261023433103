import "./BaseForm.css";

const Success = () => {
    return (
        <div id="success">
            <div className="success-wrapper" id="success-wrapper">
                <h3>Ваша заявка отправлена!</h3>
                <p>
                    Ваша заявка успешно отправлена и будет обработана менеджером
                    в ближайшее время.
                </p>
            </div>
        </div>
    );
};

export default Success;
