import React, { useContext } from "react";
import Navigation from "../navigation/Navigation";
import "./Banner.css";
import Logo from "../../svg/LogoSvg";
import Phone from "../../svg/PhoneSvg";
import Banner1Img from "../../images/banner1.jpg";
import Banner2Img from "../../images/banner2.jpg";
import Banner3Img from "../../images/banner3.jpg";
import { Carousel } from "antd";
import Bg from "../../svg/BannerBg";
import Bg2 from "../../svg/Banner2Bg";
import { AppContext } from "../context/Context";
import { motion } from "framer-motion";

const Banner = () => {
    const { setIsFormOpen } = useContext(AppContext);
    const images = [Banner1Img, Banner2Img, Banner3Img];
    return (
        <section id="banner">
            <Bg className="bg" />
            <Bg2 className="bg bg2" />
            <div className="main">
                <motion.div
                    className="header"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                >
                    <Logo className="logo" />
                    <Navigation className="navigation" />
                    <div className="phone">
                        <Phone className="phone-svg" />
                        <a
                            href="tel: +77072124444"
                            style={{ textDecoration: "none" }}
                        >
                            <span>
                                +7 707 <span>212 44 44</span>
                            </span>
                        </a>
                    </div>
                </motion.div>
                <motion.div
                    className="headings"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                >
                    <h2>Новая жизнь городской легенды</h2>
                    <h1>
                        РАССРОЧКА
                        <br /> ОТ ЗАСТРОЙЩИКА
                    </h1>
                    <h3>
                        до 40 мес. | 0% переплаты
                        <br /> 30% первоначальный взнос
                    </h3>
                    <button
                        className="primary"
                        onClick={() => setIsFormOpen(true)}
                    >
                        Оставить заявку
                    </button>
                </motion.div>
            </div>
            <motion.div
                className="carousel"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
            >
                <Carousel dots={false} autoplay autoplaySpeed={4000}>
                    {images.map((img, i) => (
                        <div key={i} className="img-con">
                            <img src={img} alt="" />
                        </div>
                    ))}
                </Carousel>
            </motion.div>
            <a href="tel:+77072124444" className="phone-mobile">
                <svg
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="phone-svg"
                >
                    <path
                        d="M10.8885 17.7099C7.35554 16.2107 4.51387 13.4453 2.91687 9.96703L2.91622 9.96561L2.48448 9.01594C1.89387 7.71681 2.33002 6.18111 3.5153 5.38635L4.626 4.6416C4.98464 4.40113 5.46756 4.47443 5.73868 4.8105L7.76358 7.32045C8.06098 7.68911 7.99171 8.2312 7.61116 8.51322L6.56345 9.28965C7.54624 11.282 9.16378 12.8995 11.1561 13.8823L11.9326 12.8346C12.2146 12.4541 12.7567 12.3848 13.1253 12.6822L15.6352 14.707C15.9713 14.9782 16.0446 15.4612 15.804 15.8199L15.0639 16.923C14.2736 18.1009 12.7505 18.5395 11.4548 17.9622L10.8885 17.7099Z"
                        fill="#F0641E"
                    ></path>
                    <path
                        d="M10.207 9.24999C10.207 9.41575 10.2729 9.57472 10.3901 9.69193C10.5073 9.80914 10.6663 9.87499 10.832 9.87499H14.0224C14.3676 9.87499 14.6474 9.59516 14.6474 9.24999C14.6474 8.90481 14.3676 8.62499 14.0224 8.62499H12.3409L14.9734 5.99248C15.2175 5.7484 15.2175 5.35267 14.9734 5.1086C14.7293 4.86452 14.3336 4.86452 14.0895 5.1086L11.457 7.7411V6.05963C11.457 5.71445 11.1772 5.43463 10.832 5.43463C10.4869 5.43463 10.207 5.71445 10.207 6.05963V9.24999Z"
                        fill="#828282"
                    ></path>
                </svg>
                <span className="grey-span"> +7 707</span>
                &nbsp;
                <span>212 44 44</span>
            </a>
        </section>
    );
};

export default Banner;
