import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navigation = (props) => {
    return (
        <nav {...props}>
            <AnchorLink href="#advantages" style={{ textDecoration: "none" }}>
                Преимущества
            </AnchorLink>
            <AnchorLink href="#location" style={{ textDecoration: "none" }}>
                Расположение
            </AnchorLink>
            <AnchorLink href="#all-in-one" style={{ textDecoration: "none" }}>
                ALL-in-ONE
            </AnchorLink>
        </nav>
    );
};

export default Navigation;
