import React, { useState } from "react";
import { motion } from "framer-motion";
import ZoomImg from "../../images/zoom.jpg";
import ZoomSvg from "../../svg/ZoomSvg";
import { Button, Modal, Carousel } from "antd";
import First1 from "../../images/first-1.png";
import First2 from "../../images/first-2.png";
import First3 from "../../images/first-3.png";
import First4 from "../../images/first-4.png";
import First5 from "../../images/first-5.png";
import Second1 from "../../images/second-1.png";
import Second2 from "../../images/second-2.png";
import Second3 from "../../images/second-3.png";
import Second4 from "../../images/second-4.png";
import Second5 from "../../images/second-5.png";
import Third1 from "../../images/third-1.png";
import Third2 from "../../images/third-2.png";
import Third3 from "../../images/third-3.png";
import Third4 from "../../images/third-4.png";
import Third5 from "../../images/third-5.png";
import Arrow from "../../svg/ArrowSvg";
import "./Panorama.css";

const Panorama = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [carouselImages, setCarouselImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const carouselRef = React.createRef();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (images) => {
        setIsModalOpen(true);
        setCarouselImages(images);
        document.body.style.overflow = "hidden";
    };

    const handleOk = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "";
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "";
    };

    const onChange = (index) => {
        setCurrentImageIndex(index);
    };

    return (
        <motion.div
            className="zoom"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1 }}
        >
            <img className="zoom-img" src={ZoomImg} alt="" />
            <div
                className="zoom-btn one"
                onClick={() =>
                    showModal([First1, First2, First3, First4, First5])
                }
            >
                <ZoomSvg className="zoom-svg" />
            </div>
            <div
                className="zoom-btn two"
                onClick={() =>
                    showModal([Second1, Second2, Second3, Second4, Second5])
                }
            >
                <ZoomSvg className="zoom-svg" />
            </div>
            <div
                className="zoom-btn three"
                onClick={() =>
                    showModal([Third1, Third2, Third3, Third4, Third5])
                }
            >
                <ZoomSvg className="zoom-svg" />
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                centered={true}
            >
                <Button
                    onClick={() => {
                        carouselRef.current.prev();
                    }}
                >
                    <Arrow width={20} height={20} />
                </Button>
                <Carousel afterChange={onChange} ref={carouselRef} dots={false}>
                    {carouselImages.map((item, index) => (
                        <div className="panorama-slide" key={index}>
                            <img src={item} alt="slide-image" />
                        </div>
                    ))}
                </Carousel>
                <Button
                    onClick={() => {
                        carouselRef.current.next();
                    }}
                >
                    <Arrow width={20} height={20} />
                </Button>
            </Modal>
        </motion.div>
    );
};

export default Panorama;
